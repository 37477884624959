#editor-container,
#viewonly-editor-container {
  height: 100vh;
  width: 100%;
}

@supports (height: 100dvh) {
  #editor-container,
  #viewonly-editor-container {
    height: 100dvh;
  }
}
